// TenantPaymentRequest.js
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../utils/IP'; 
import { 
  Container, 
  Box, 
  Typography, 
  IconButton, 
  TextField, 
  Button, 
  Paper, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Snackbar, 
  Card, 
  CardMedia, 
  CardActions 
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const TenantPaymentRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const initialDue = location.state?.selectedDue;

  const [tenantName, setTenantName] = useState('');
  const [dueAmount, setDueAmount] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [collectionMode, setCollectionMode] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDue, setSelectedDue] = useState(initialDue?._id || 'all');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const { user } = useSelector(state => state.auth);
  const dues = useSelector(state => state.dueAndComplaint.dues);
  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);

  const pendingDues = useMemo(() => 
    dues.filter(d => d.status === 'Pending'),
    [dues]
  );

  useEffect(() => {
    if (tenantDetails) {
      setTenantName(tenantDetails.tenant.name);
    }
    
    // Set initial values based on selected due or total
    if (initialDue) {
      setPaymentAmount(initialDue.dueAmount.toString());
      setDueAmount(initialDue.dueAmount);
    } else {
      const totalDueAmount = pendingDues.reduce((acc, due) => acc + due.dueAmount, 0);
      setDueAmount(totalDueAmount);
      setPaymentAmount(totalDueAmount.toString());
    }
  }, [user, pendingDues, tenantDetails, initialDue]);

  const handleDueSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDue(selectedValue);

    if (selectedValue === 'all') {
      const totalAmount = pendingDues.reduce((acc, due) => acc + due.dueAmount, 0);
      setPaymentAmount(totalAmount.toString());
      setDueAmount(totalAmount);
    } else {
      const due = pendingDues.find(d => d._id === selectedValue);
      if (due) {
        setPaymentAmount(due.dueAmount.toString());
        setDueAmount(due.dueAmount);
      }
    }
  };

  const handleCollectionModeChange = (event) => {
    setCollectionMode(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleScreenshotUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPaymentScreenshot(file);
      const fileReader = new FileReader();
      fileReader.onload = (e) => setPreviewUrl(e.target.result);
      fileReader.readAsDataURL(file);
    }
  };

  const handleDeleteScreenshot = () => {
    setPaymentScreenshot(null);
    setPreviewUrl(null);
  };

  const handleSubmitPayment = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (selectedDue === 'all') {
      formData.append('allDues', 'true');
    } else {
      formData.append('dueId', selectedDue);
    }

    formData.append('amount', paymentAmount);
    formData.append('paymentMode', collectionMode);
    formData.append('description', description);
    if (paymentScreenshot) {
      formData.append('paymentScreenshot', paymentScreenshot);
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/dues/submit-payment-request/${tenantDetails.tenant._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          },
        }
      );

      setSnackbarMessage('Payment request submitted successfully');
      setSnackbarOpen(true);
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error('Error submitting payment request:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error submitting payment request');
      setSnackbarOpen(true);
    }
  };

  if (pendingDues.length === 0) {
    return (
      <Container maxWidth="sm" disableGutters>
        <Box sx={{
          bgcolor: '#FFF',
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: '#303030',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
            <IconButton onClick={handleBackClick} sx={{ color: '#303030' }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
              Submit Payment Request
            </Typography>
          </Box>
        </Box>
        <Paper sx={{ mt: 2, p: 3, mx: 0 }}>
          <Typography>You have no pending dues at the moment.</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <Box sx={{
        bgcolor: '#FFF',
        pb: 2,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
        mx: 0,
        px: 2,
        color: '#303030',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: '#303030' }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Submit Payment Request
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 3, mx: 0 }}>
        <TextField
          fullWidth
          label="Tenant Name"
          variant="outlined"
          value={tenantName}
          disabled
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="due-selection-label">Select Due</InputLabel>
          <Select
            labelId="due-selection-label"
            value={selectedDue}
            onChange={handleDueSelectionChange}
            label="Select Due"
            disabled={initialDue !== null}
          >
            <MenuItem value="all">All Pending Dues</MenuItem>
            {pendingDues.map((due) => (
              <MenuItem key={due._id} value={due._id}>
                {due.dueType} (₹{due.dueAmount})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Payment Amount"
          variant="outlined"
          value={paymentAmount}
          type="number"
          sx={{ mb: 2 }}
          disabled={true}
          InputProps={{
            readOnly: true,
          }}
        />

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="collection-mode-label">Payment Mode</InputLabel>
          <Select
            labelId="collection-mode-label"
            value={collectionMode}
            onChange={handleCollectionModeChange}
            label="Payment Mode"
            required
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mb: 2 }}
        >
          Upload Payment Screenshot
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleScreenshotUpload}
          />
        </Button>

        {previewUrl && (
          <Card sx={{ mb: 2 }}>
            <CardMedia
              component="img"
              image={previewUrl}
              alt="Payment Screenshot"
              sx={{ height: 200, objectFit: 'cover' }}
            />
            <CardActions>
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleDeleteScreenshot}
                color="error"
              >
                Delete Screenshot
              </Button>
            </CardActions>
          </Card>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!collectionMode}
          sx={{ bgcolor: '#303030', color: 'white' }}
          onClick={handleSubmitPayment}
        >
          Submit Payment Request
        </Button>
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default TenantPaymentRequest;