import React from 'react';

const PulsatingOrbitLoader = () => {
  // Replace with your actual logo file name
  const logoFile = "/icons/blackrizzyicon.png";

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f5f5f5',
      overflow: 'hidden',
    }}>
      <div className="loader-container">
        <img src={process.env.PUBLIC_URL + logoFile} alt="Logo" className="logo" />
        <div className="orbit"></div>
        <div className="orbit"></div>
        <div className="orbit"></div>
      </div>
      <style>{`
        .loader-container {
          position: relative;
          width: 200px;
          height: 200px;
        }
        .logo {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 60px;
          height: 60px;
          transform: translate(-50%, -50%);
          object-fit: contain;
          animation: pulse 2s ease-in-out infinite;
        }
        .orbit {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid #03A0A2;
          border-radius: 50%;
          opacity: 0;
        }
        .orbit:nth-child(2) {
          animation: orbit 2s linear infinite, fade 2s ease-in-out infinite;
        }
        .orbit:nth-child(3) {
          animation: orbit 2s linear infinite 0.6s, fade 2s ease-in-out infinite 0.6s;
        }
        .orbit:nth-child(4) {
          animation: orbit 2s linear infinite 1.2s, fade 2s ease-in-out infinite 1.2s;
        }
        @keyframes pulse {
          0%, 100% { transform: translate(-50%, -50%) scale(1); }
          50% { transform: translate(-50%, -50%) scale(1.1); }
        }
        @keyframes orbit {
          0% { transform: rotate(0deg) scale(0.5); }
          100% { transform: rotate(360deg) scale(1.2); }
        }
        @keyframes fade {
          0%, 100% { opacity: 0; }
          50% { opacity: 0.7; }
        }
      `}</style>
      <div> loading, Please wait... </div>
    </div>
  );
};

export default PulsatingOrbitLoader;