import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  Modal,
  Fade,
  Divider,
  Chip,
  LinearProgress,
  Avatar,
  AvatarGroup,
  Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import PersonIcon from '@mui/icons-material/Person';
import { BASE_URL } from '../utils/IP';
import colors from '../styles/colors';

const MetricCard = ({ icon: Icon, title, value, color, bgColor }) => (
  <Card sx={{ 
    bgcolor: bgColor || '#fff',
    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
    borderRadius: 3,
    height: '100%'
  }}>
    <CardContent sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Avatar sx={{ bgcolor: color || 'primary.main', width: 32, height: 32 }}>
          <Icon sx={{ fontSize: 18 }} />
        </Avatar>
        <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const TappRoomProfile = () => {
  const [openPreview, setOpenPreview] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const tenantDetails = useSelector(state => state.dueAndComplaint.currentOccupancy);

  if (!tenantDetails?.room) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>Room details not available</Typography>
      </Box>
    );
  }

  const { room } = tenantDetails;

  // Safely access room properties with default values
  const facilities = room?.facilities || [];
  const roomTypes = room?.roomTypes || [];
  const media = room?.media || [];
  const tenants = room?.tenants || [];
  const currentOcc = room?.currentOcc || 0;
  const maxOcc = room?.maxOcc || 1;

  const handleImagePreview = (index) => {
    setCurrentImageIndex(index);
    setOpenPreview(true);
  };

  const handleNextImage = () => {
    if (!media.length) return;
    setCurrentImageIndex((prev) => 
      prev === (media.length - 1) ? 0 : prev + 1
    );
  };

  const handlePrevImage = () => {
    if (!media.length) return;
    setCurrentImageIndex((prev) => 
      prev === 0 ? (media.length - 1) : prev - 1
    );
  };

  // Calculate occupancy percentage
  const occupancyPercentage = (currentOcc / maxOcc) * 100;

  // Organize amenities by category
  const amenityCategories = {
    comfort: ['AC', 'Cooler', 'Fan'],
    furniture: ['Table', 'Chair', 'Almirah', 'Bed'],
    electronics: ['TV', 'Fridge', 'Microwave', 'Geyser'],
    facilities: ['Washroom', 'Balcony', 'Kitchen', 'Laundry'],
    security: ['CCTV', 'Security Guard', 'Digital Lock']
  };

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 4 }}>
     
     <Box
        sx={{
          bgcolor: colors.secondary,
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100vw',
          zIndex: 1100,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: colors.primary}}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, color: colors.primary, textShadow: '0 2px 4px rgba(0,0,0,0.2)' }}>
            Room {room.name}
          </Typography>
        </Box>
         {/* Header Section */}
      
        
      </Box>
{/* Main Image or Color Block */}
{media.length > 0 ? (
          <Box
            component="img"
            src={`${BASE_URL}${media[0].path}`}
            alt="Room"
            sx={{
              width: '100%',
              height: '100px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box sx={{ width: '100%', height: '100%', bgcolor: 'grey.200' }} />
        )}
      

      {/* Quick Stats Section */}
      <Box sx={{ mt: -4, mx: 2, mb: 3, position: 'relative', zIndex: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MetricCard
              icon={PeopleAltIcon}
              title="Occupancy"
              value={`${currentOcc}/${maxOcc} Occupied`}
              color="#2196F3"
              bgColor="#F8FBFF"
            />
          </Grid>
          <Grid item xs={6}>
            <MetricCard
              icon={SquareFootIcon}
              title="Area"
              value={`${room.area || 0} sq.ft`}
              color="#4CAF50"
              bgColor="#F6FBF6"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Room Images Gallery */}
      {media.length > 0 && (
        <Box sx={{ px: 2, mb: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
            Room Gallery
          </Typography>
          <ImageList cols={3} gap={8} sx={{ overflow: 'hidden', borderRadius: 2 }}>
            {media.map((item, index) => (
              <ImageListItem 
                key={index}
                onClick={() => handleImagePreview(index)}
                sx={{ 
                  cursor: 'pointer',
                  overflow: 'hidden',
                  borderRadius: 2,
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <img
                  src={`${BASE_URL}${item.path}`}
                  alt={`Room view ${index + 1}`}
                  loading="lazy"
                  style={{ 
                    height: '100px',
                    objectFit: 'cover',
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}

      {/* Room Details */}
      <Box sx={{ px: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Room Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, borderRadius: 3, bgcolor: '#FFF5F8',boxShadow:0 }}>
              <Typography color="textSecondary" variant="body2">Unit Type</Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>{room.unitType || 'Not specified'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, borderRadius: 3, bgcolor: '#F5F9FF' ,boxShadow:0}}>
              <Typography color="textSecondary" variant="body2">Floor</Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>{room.floor || 'Not specified'}</Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Occupancy Progress */}
        <Box sx={{ mt: 3, mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="textSecondary">
              Room Occupancy
            </Typography>
            <Typography variant="body2" color="primary" sx={{ fontWeight: 600 }}>
              {occupancyPercentage}%
            </Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={occupancyPercentage}
            sx={{
              height: 8,
              borderRadius: 4,
              bgcolor: '#E0E0E0',
              '& .MuiLinearProgress-bar': {
                bgcolor: '#2196F3',
                borderRadius: 4,
              }
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="caption" color="textSecondary">
              {currentOcc} Occupied
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {maxOcc - currentOcc} Available
            </Typography>
          </Box>
        </Box>

        {/* Current Tenants */}
        {tenants.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
              Current Tenants
            </Typography>
            <AvatarGroup max={4} sx={{ justifyContent: 'flex-start' }}>
              {tenants.map((tenant, index) => (
                <Avatar
                  key={index}
                  alt={tenant.name}
                  src={tenant.profilePicture ? `${BASE_URL}${tenant.profilePicture}` : undefined}
                  sx={{ width: 40, height: 40 }}
                >
                  {tenant.name ? tenant.name[0] : 'T'}
                </Avatar>
              ))}
            </AvatarGroup>
          </Box>
        )}

        {/* Amenities Section */}
        {facilities.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
              Room Amenities
            </Typography>
            {Object.entries(amenityCategories).map(([category, items]) => {
              const availableItems = items.filter(item => 
                facilities.includes(item)
              );
              
              if (availableItems.length === 0) return null;

              return (
                <Box key={category} sx={{ mb: 3 }}>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1, textTransform: 'capitalize' }}>
                    {category}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {availableItems.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        size="small"
                        sx={{
                          bgcolor: '#F5F5F5',
                          '& .MuiChip-label': { px: 2 }
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              );
            })}
          </>
        )}
      </Box>

      {/* Image Preview Modal */}
      <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        closeAfterTransition
      >
        <Fade in={openPreview}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            outline: 'none',
          }}>
            {media.length > 0 && (
              <>
                <IconButton
                  onClick={() => setOpenPreview(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    zIndex: 1,
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={`${BASE_URL}${media[currentImageIndex].path}`}
                    alt="Room view"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '80vh',
                      objectFit: 'contain',
                    }}
                  />
                  {media.length > 1 && (
                    <>
                      <IconButton
                        onClick={handlePrevImage}
                        sx={{
                          position: 'absolute',
                          left: 8,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          bgcolor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
                        }}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleNextImage}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          bgcolor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
                        }}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default TappRoomProfile;