import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Card,
  IconButton,
  CircularProgress,
  Chip,
  TextField,
  InputAdornment,
  Fade,
  Container,
  Badge,
  ToggleButton,
  ToggleButtonGroup,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PaymentsIcon from '@mui/icons-material/Payments';
import PendingIcon from '@mui/icons-material/Pending';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useNavigate } from 'react-router-dom';
import colors from '../styles/colors';

const TenantDueList = () => {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);
  const dues = useSelector(state => state.dueAndComplaint.dues);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('pending'); // Default to pending

  const navigate = useNavigate();

  // Filter out paid dues since they're in transactions
  const relevantDues = dues.filter(due => due.status !== 'Paid');
  
  const pendingDuesCount = relevantDues.filter(due => due.status === 'Pending').length;
  const verificationDuesCount = relevantDues.filter(due => due.status === 'UnderVerification').length;

  const handleBackClick = () => {
    window.scrollTo(0, 0);
    navigate(-1);
  };

  const handleDueClick = (due) => {
    if (due.status === 'Pending') {
      navigate('/TenantPaymentRequest', {
        state: { selectedDue: due }
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'UnderVerification':
        return '#FF9800';
      case 'Pending':
      default:
        return '#F44336';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'UnderVerification':
        return <PendingIcon />;
      case 'Pending':
      default:
        return <WarningAmberIcon />;
    }
  };

  const filteredDues = relevantDues.filter(due => 
    (statusFilter === 'all' || due.status.toLowerCase() === statusFilter) &&
    due.dueType?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      <Box
        sx={{
          bgcolor: colors.secondary,
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100vw',
          zIndex: 1100,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, color: colors.primary, fontWeight: 600 }}>
            Your Dues
          </Typography>
          <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
            {pendingDuesCount > 0 && (
              <Badge
                badgeContent={pendingDuesCount}
                color="error"
                sx={{ mr: 1 }}
              >
                <Chip
                  label="P"
                  size="small"
                  sx={{
                    bgcolor: 'rgba(244, 67, 54, 0.1)',
                    color: '#F44336',
                    fontWeight: 500
                  }}
                />
              </Badge>
            )}
            {verificationDuesCount > 0 && (
              <Badge
                badgeContent={verificationDuesCount}
                color="warning"
              >
                <Chip
                  label="UV"
                  size="small"
                  sx={{
                    bgcolor: 'rgba(255, 152, 0, 0.1)',
                    color: '#FF9800',
                    fontWeight: 500
                  }}
                />
              </Badge>
            )}
          </Box>
        </Box>
      </Box>

      {/* Status Filter */}
      <Box sx={{ px: 2, pt: 2 }}>
        <ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={(e, newValue) => newValue && setStatusFilter(newValue)}
          fullWidth
          sx={{ mb: 2 }}
        >
         
          <ToggleButton 
            value="pending"
            sx={{ 
              textTransform: 'none',
              '&.Mui-selected': { bgcolor: 'rgba(244, 67, 54, 0.1)' }
            }}
          >
            Pending
          </ToggleButton>
          <ToggleButton 
            value="underverification"
            sx={{ 
              textTransform: 'none',
              '&.Mui-selected': { bgcolor: 'rgba(255, 152, 0, 0.1)' }
            }}
          >
            Verifying
          </ToggleButton>
        </ToggleButtonGroup>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search dues..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: colors.teal }} />
              </InputAdornment>
            ),
          }}
          sx={{
            bgcolor: 'white',
            borderRadius: '12px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              '& fieldset': { borderColor: 'transparent' },
              '&:hover fieldset': { borderColor: 'transparent' },
              '&.Mui-focused fieldset': { borderColor: colors.teal },
            },
          }}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        {filteredDues.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8, 
            p: 3, 
            bgcolor: 'rgba(0,0,0,0.02)',
            borderRadius: 2
          }}>
            <CurrencyRupeeIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No dues found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {searchTerm ? 'Try different search terms' : 
                statusFilter === 'pending' ? 'No pending dues' :
                statusFilter === 'underverification' ? 'No dues under verification' :
                'No dues to display'}
            </Typography>
          </Box>
        ) : (
          filteredDues.map((due, index) => (
            <Fade in={true} key={due._id || index} timeout={300 + index * 100}>
              <Card
                onClick={() => handleDueClick(due)}
                sx={{
                  mb: 2,
                  ml: 2,
                  mr: 2,
                  borderRadius: 3,
                  cursor: due.status === 'Pending' ? 'pointer' : 'default',
                  bgcolor: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': due.status === 'Pending' ? {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  } : {},
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: `${getStatusColor(due.status)}15`,
                        }}
                      >
                        {getStatusIcon(due.status)}
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
                          {due.dueType}
                        </Typography>
                        <Typography variant="h6" sx={{ color: colors.teal, fontWeight: 'bold' }}>
                          ₹{due.dueAmount}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip
                        label={due.status}
                        size="small"
                        sx={{
                          bgcolor: `${getStatusColor(due.status)}15`,
                          color: getStatusColor(due.status),
                          fontWeight: 500,
                        }}
                      />
                      {due.status === 'Pending' && <KeyboardArrowRightIcon sx={{ color: colors.teal }} />}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      Due Date: {new Date(due.dueDate).toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                    {due.status === 'UnderVerification' && (
                      <Typography variant="caption" sx={{ color: '#FF9800', fontStyle: 'italic' }}>
                        Payment under verification
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Card>
            </Fade>
          ))
        )}
      </Box>

      {statusFilter === 'pending' && filteredDues.filter(due => due.status === 'Pending').length > 0 && (
        <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
          <Button
            variant="contained"
            onClick={() => navigate('/TenantPaymentRequest')}
            startIcon={<PaymentsIcon />}
            sx={{
              bgcolor: colors.teal,
              borderRadius: '24px',
              px: 3,
              py: 1.5,
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
              '&:hover': {
                bgcolor: colors.darkteal,
              }
            }}
          >
            Pay All Dues
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default TenantDueList;