// src/middleware/websocketMiddleware.js (Homeowner App)

import WebSocketService from '../services/websocketService';
import { fetchDuesAndComplaints } from '../features/duesComplaintsSlice';
import { REHYDRATE } from 'redux-persist';

let listenersInitialized = false;  // Add this flag to track listener initialization

const websocketMiddleware = store => next => action => {
  switch (action.type) {
    case 'auth/login/fulfilled':
      if (!WebSocketService.socket?.connected) {
        WebSocketService.connect(action.payload);
        setupWebSocketListeners(store);
      }
      break;

    case 'auth/logout':
      listenersInitialized = false;  // Reset the flag on logout
      WebSocketService.disconnect();
      break;

    case REHYDRATE:
      // Only setup if not already initialized
      if (!listenersInitialized && localStorage.getItem('token')) {
        WebSocketService.reconnectIfNeeded();
        setupWebSocketListeners(store);
      }
      break;

    case 'APP_INITIALIZED':
      // Only setup if not already initialized
      if (!listenersInitialized && localStorage.getItem('token')) {
        WebSocketService.reconnectIfNeeded();
        setupWebSocketListeners(store);
      }
      break;

    default:
      break;
  }

  return next(action);
};

function setupWebSocketListeners(store) {
    // Only set up listeners once
  if (listenersInitialized) {
    return;
  }

  WebSocketService.off('TENANT_UPDATE');  // Clear any existing listeners
  WebSocketService.on('TENANT_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware TENANT UPDATE')
    const occupancyId = state.auth.user.currentOccupancyId;
    if (occupancyId) {
      store.dispatch(fetchDuesAndComplaints('occupancy'));
      console.log('dispatcing occup')
    }
  });

  WebSocketService.on('DUE_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware DUE UPDATE')
    const tenantId = state.auth.user.tenantId;
    if (tenantId) {
      store.dispatch(fetchDuesAndComplaints('dues'));
    }
  });

  WebSocketService.on('COMPLAINT_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware COMPLAINT UPDATE')
    const tenantId = state.auth.user.tenantId;
    if (tenantId) {
      store.dispatch(fetchDuesAndComplaints('complaints'));
    }
  });

  listenersInitialized = true;  // Mark listeners as initialized

}

export default websocketMiddleware;