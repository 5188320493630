import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import LogoDispersionLoader from './LogoDispersionLoader';

const AppLoaderWrapper = ({ children }) => {
  const isLoading = useSelector(state => state.dueAndComplaint.loading);

  if (isLoading) {
    return (
      <Box >
        <LogoDispersionLoader />
      </Box>
    );
  }

  return children;
};

export default AppLoaderWrapper;