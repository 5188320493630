import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import LoginPage from './TApp-components/Tapp-login';
import SignupPage from './TApp-components/Tapp-signup';
import TenantAppHome from './TApp-components/TenantAppHome';
import TenantAppProfile from './TApp-components/Tapp-profile';
import TenantAppAccount from './TApp-components/Tapp-account';
import TenantAppReportComplaint from './TApp-components/Tapp-addcomplaint';
import ProtectedRoute from './TApp-components/ProtectedRoute';
import TenantComplaints from './TApp-components/Tapp-complaintlist';
import TappComplaintDetails from './TApp-components/Tapp-complaintdetails';
import TenantDues from './TApp-components/Tapp-duelist';
import TenantTransactions from './TApp-components/Tapp-transactions';
import AppLoaderWrapper from './TApp-components/AppLoaderWrapper';
import PastProperties from './TApp-components/PastProperties';
import { CircularProgress } from '@mui/material';
import TenantPaymentRequest from './TApp-components/TenantPaymentRequest';
import RoomProfile from './TApp-components/RoomProfile';

// New component to handle app initialization
const AppInitializer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'APP_INITIALIZED' });
  }, [dispatch]);

  return children;
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <AppInitializer>
          <AppLoaderWrapper>
            <Router>
              <Routes>
                <Route path="/tapp-login" element={<LoginPage />} />
                <Route path="/tapp-signup" element={<SignupPage />} />

                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<TenantAppHome />} />
                  <Route path="/tapp-addcomplaint" element={<TenantAppReportComplaint />} />
                  <Route path="/tapp-account" element={<TenantAppAccount />} />
                  <Route path="/tapp-complaints" element={<TenantComplaints />} />
                  <Route path="/tapp-complaint-details/:complaintId" element={<TappComplaintDetails />} />
                  <Route path="/tapp-dues" element={<TenantDues />} />
                  <Route path="/tapp-transactions" element={<TenantTransactions />} />
                  <Route path="/tapp-home" element={<TenantAppHome />} />
                  <Route path="/tapp-profile" element={<TenantAppProfile />} />
                  <Route path="/tapp-pastproperties" element={<PastProperties />} />
                  <Route path="/TenantPaymentRequest" element={<TenantPaymentRequest />} />
                  <Route path="/RoomProfile" element={<RoomProfile />} />
                </Route>
                <Route path="*" element={<Navigate to="/tapp-login" />} />
              </Routes>
            </Router>
          </AppLoaderWrapper>
        </AppInitializer>
      </PersistGate>
    </Provider>
  );
};

export default App;