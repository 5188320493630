// Tapp-transactions.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  IconButton,
  CircularProgress,
  Chip,
  TextField,
  InputAdornment,
  Fade,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Snackbar
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import colors from '../styles/colors';
import { BASE_URL } from '../utils/IP';

const AllTransactions = () => {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);
  const alldues = useSelector(state => state.dueAndComplaint.dues);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigate = useNavigate();

  const transactions = alldues
    .filter((due) => due.status === 'Paid')
    .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    window.scrollTo(0, 0);
    navigate(-1);
  };

  const handleViewReceipt = (transaction) => {
    setSelectedReceipt(transaction);
    setReceiptDialogOpen(true);
  };

  const handleCloseReceipt = () => {
    setReceiptDialogOpen(false);
  };

  const handleDownloadPDF = (pdfUrl) => {
    if (pdfUrl) {
      window.open(`${BASE_URL}${pdfUrl}`, '_blank');
    } else {
      setSnackbarMessage('PDF not available for this receipt');
      setSnackbarOpen(true);
    }
  };

  const handleShareReceipt = async (receipt) => {
    if (navigator.share && receipt.receipt && receipt.receipt.pdfUrl) {
      try {
        await navigator.share({
          title: 'Payment Receipt',
          text: `Receipt for payment of ₹${receipt.paymentAmount} on ${new Date(receipt.paymentDateTime).toLocaleDateString()}`,
          url: `${BASE_URL}${receipt.receipt.pdfUrl}`
        });
      } catch (error) {
        console.error('Error sharing:', error);
        setSnackbarMessage('Error sharing receipt');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Sharing is not supported or receipt URL is not available');
      setSnackbarOpen(true);
    }
  };

  const filteredTransactions = transactions.filter(transaction =>
    transaction.dueType?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: colors.secondary,
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100vw',
          zIndex: 1100,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, color: colors.primary, fontWeight: 600 }}>
            Transaction History
          </Typography>
        </Box>
      </Box>

      {/* Search Bar */}
      <Box sx={{ px: 2, pb: 2, mt: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search transactions..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: colors.teal }} />
              </InputAdornment>
            ),
          }}
          sx={{
            bgcolor: 'white',
            borderRadius: '12px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              '& fieldset': { borderColor: 'transparent' },
              '&:hover fieldset': { borderColor: 'transparent' },
              '&.Mui-focused fieldset': { borderColor: colors.teal },
            },
          }}
        />
      </Box>

      {/* Transaction List */}
      <Box sx={{ mt: 2 }}>
        {filteredTransactions.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8, 
            p: 3, 
            bgcolor: 'rgba(0,0,0,0.02)',
            borderRadius: 2
          }}>
            <PaymentIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No transactions found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {searchTerm ? 'Try different search terms' : 'You have no payment history yet'}
            </Typography>
          </Box>
        ) : (
          filteredTransactions.map((transaction, index) => (
            <Fade in={true} key={transaction._id || index} timeout={300 + index * 100}>
              <Card
                sx={{
                  mb: 2,
                  ml: 2,
                  mr: 2,
                  borderRadius: 3,
                  bgcolor: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  cursor: transaction.receipt ? 'pointer' : 'default',
                  '&:hover': transaction.receipt ? {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  } : {},
                }}
                onClick={() => transaction.receipt && handleViewReceipt(transaction)}
              >
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'rgba(76, 175, 80, 0.1)',
                        }}
                      >
                        <PaymentIcon sx={{ color: '#4CAF50' }} />
                      </Box>
                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
                          {transaction.dueType}
                        </Typography>
                        <Typography variant="h6" sx={{ color: colors.teal, fontWeight: 'bold' }}>
                          ₹{transaction.paymentAmount}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip
                        label={transaction.paymentMode}
                        size="small"
                        sx={{
                          bgcolor: 'rgba(76, 175, 80, 0.1)',
                          color: '#4CAF50',
                          fontWeight: 500,
                        }}
                      />
                      {transaction.receipt && <KeyboardArrowRightIcon sx={{ color: colors.teal }} />}
                    </Box>
                  </Box>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(transaction.paymentDateTime).toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                </Box>
              </Card>
            </Fade>
          ))
        )}
      </Box>

      {/* Receipt Dialog */}
      <Dialog 
        open={receiptDialogOpen} 
        onClose={handleCloseReceipt} 
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle>
          Receipt Details
          <IconButton
            aria-label="close"
            onClick={handleCloseReceipt}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedReceipt && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Amount Paid:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>₹{selectedReceipt.paymentAmount}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Payment Date:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {new Date(selectedReceipt.paymentDateTime).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Due Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{selectedReceipt.dueType}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Payment Mode:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{selectedReceipt.paymentMode}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Receipt ID:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {selectedReceipt.receipt ? selectedReceipt.receipt.receiptId : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReceipt}>Close</Button>
          <Button 
            onClick={() => selectedReceipt && selectedReceipt.receipt && handleDownloadPDF(selectedReceipt.receipt.pdfUrl)}
            disabled={!selectedReceipt || !selectedReceipt.receipt || !selectedReceipt.receipt.pdfUrl}
            startIcon={<GetAppIcon />}
          >
            Download
          </Button>
          <Button 
            onClick={() => selectedReceipt && handleShareReceipt(selectedReceipt)}
            disabled={!selectedReceipt || !selectedReceipt.receipt || !selectedReceipt.receipt.pdfUrl}
            startIcon={<ShareIcon />}
          >
            Share
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default AllTransactions;