import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { persistor } from '../store'; // Import the persistor
import { resetDueAndComplaint,fetchDuesAndComplaints } from './duesComplaintsSlice'; // Import the reset action




export const checkAuthStatus = createAsyncThunk(
  'auth/checkAuthStatus',
  async (_, { getState }) => {
    const { auth } = getState();
    if (auth.isAuthenticated && auth.user) {
      return auth.user;
    }
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        return {
          userId: decoded.userId,
          role: decoded.role,
          tenantId: decoded.tenantId,
          currentOccupancyId: decoded.currentOccupancyId
        };
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('token');
        throw error;
      }
    }
    throw new Error('No token found');
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(login.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      // You might want to set other relevant state here
    })
      .addCase(checkAuthStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.user = null;
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});

export const { clearUser } = authSlice.actions;

export const login = createAsyncThunk(
  'auth/login',
  async (token, { dispatch }) => {
    localStorage.setItem('token', token);
    await dispatch(checkAuthStatus()).unwrap();
    await dispatch(fetchDuesAndComplaints('all')).unwrap();
    console.log('Data fetched, navigating to home...');
    return token;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    localStorage.removeItem('token');
    await persistor.purge(); // Purge all persisted data
    dispatch(clearUser());
    dispatch(resetDueAndComplaint()); // Reset the dueAndComplaint slice

  }
);

export default authSlice.reducer;