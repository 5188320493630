import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Card,
  IconButton,
  CircularProgress,
  Chip,
  TextField,
  InputAdornment,
  Fade,
  Container,
  Badge
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ElectricityIcon from '@mui/icons-material/ElectricBolt';
import WaterIcon from '@mui/icons-material/WaterDrop';
import SecurityIcon from '@mui/icons-material/Security';
import CleaningIcon from '@mui/icons-material/CleaningServices';
import BuildIcon from '@mui/icons-material/Build';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useNavigate } from 'react-router-dom';
import colors from '../styles/colors';

const TenantComplaintList = () => {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);
  const complaints  = useSelector(state => state.dueAndComplaint.complaints);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: 24 } };
    switch (category?.toLowerCase()) {
      case 'electricity':
        return <ElectricityIcon {...iconProps} />;
      case 'water':
        return <WaterIcon {...iconProps} />;
      case 'security':
        return <SecurityIcon {...iconProps} />;
      case 'cleaning':
        return <CleaningIcon {...iconProps} />;
      case 'maintenance':
        return <BuildIcon {...iconProps} />;
      default:
        return <ReportProblemIcon {...iconProps} />;
    }
  };

  const handleBackClick = () => {
    window.scrollTo(0, 0);
    navigate(-1);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleComplaintClick = (complaintId) => {
    navigate(`/tapp-complaint-details/${complaintId}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Resolved':
        return '#4CAF50';
      case 'InProgress':
        return '#FF9800';
      default:
        return '#F44336';
    }
  };

  const filteredComplaints = complaints.filter(complaint => 
    complaint.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    complaint.tenant?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pendingCount = complaints.filter(c => c.status !== 'Resolved').length;

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: colors.secondary,
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          width: '100vw', // Full viewport width
          zIndex: 1100,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Box sx={{ px: 2, py: 2, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1, color: colors.primary, fontWeight: 600 }}>
            Your Complaints
          </Typography>
          
        </Box>
        </Box>
        {/* Search Bar */}
        <Box sx={{ px: 2, pb: 2,mt:2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search complaints..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: colors.teal }} />
                </InputAdornment>
              ),
            }}
            sx={{
              bgcolor: 'white',
              borderRadius: '12px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '& fieldset': { borderColor: 'transparent' },
                '&:hover fieldset': { borderColor: 'transparent' },
                '&.Mui-focused fieldset': { borderColor: colors.teal },
              },
            }}
          />
        </Box>
      

      {/* Complaints List */}
      <Box sx={{ mt: 2 }}>
        {filteredComplaints.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            mt: 8, 
            p: 3, 
            bgcolor: 'rgba(0,0,0,0.02)',
            borderRadius: 2
          }}>
            <ReportProblemIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No complaints found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {searchTerm ? 'Try different search terms' : "You haven't filed any complaints yet"}
            </Typography>
          </Box>
        ) : (
          filteredComplaints.map((complaint, index) => (
            <Fade in={true} key={complaint._id || index} timeout={3 + index * 1}>
              <Card
                onClick={() => handleComplaintClick(complaint._id)}
                sx={{
                  mb: 2,
                  ml:2,
                  mr:2,
                  borderRadius: 3,
                  cursor: 'pointer',
                  bgcolor: 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.10)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: `${getStatusColor(complaint.status)}15`,
                        }}
                      >
                        {getCategoryIcon(complaint.category)}
                      </Box>
                      <Box sx={{ml:1}}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: colors.primary }}>
                          {complaint.category}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip
                        label={complaint.status}
                        size="small"
                        sx={{
                          bgcolor: `${getStatusColor(complaint.status)}15`,
                          color: getStatusColor(complaint.status),
                          fontWeight: 500,
                        }}
                      />
                      <KeyboardArrowRightIcon sx={{ color: colors.teal }} />
                    </Box>
                  </Box>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(complaint.date).toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </Typography>
                </Box>
              </Card>
            </Fade>
          ))
        )}
      </Box>

      
    </Container>
  );
};

export default TenantComplaintList;