import React, { useEffect, useMemo } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  ButtonBase,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  IconButton,
  Badge,
  Divider
} from '@mui/material';
import {
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  Star as StarIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ChevronRight as ChevronRightIcon,
  Notifications as NotificationsIcon,
  PaymentRounded as PaymentIcon,
  Description as DescriptionIcon,
  Receipt as ReceiptIcon,
  Home as HomeIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../features/authSlice';
import LogoDispersionLoader from './LogoDispersionLoader';
import { BASE_URL } from '../utils/IP';
import colors from '../styles/colors';

const ActionCard = ({ icon: Icon, title, subtitle, onClick, color = '#E5F4F3', textColor = '#167D7F' }) => (
  <ButtonBase
    sx={{
      mt: 2,
      display: 'flex',
      width: '100%',
      p: 2,
      bgcolor: color,
      borderRadius: '10px',
      textAlign: 'left',
      textTransform: 'none',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.01)'
      }
    }}
    onClick={onClick}
  >
    <Icon sx={{ mr: 2, fontSize: 30, color: textColor }} />
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold', color: textColor }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {subtitle}
      </Typography>
    </Box>
    <ArrowForwardIosIcon sx={{ ml: 'auto', fontSize: 15, color: textColor }} />
  </ButtonBase>
);

const TransactionItem = ({ transaction }) => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    p: 2,
    '&:hover': { bgcolor: 'rgba(0,0,0,0.02)' },
    borderRadius: 2
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        bgcolor: '#E5F4F3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 2
      }}>
        <ReceiptIcon sx={{ color: '#167D7F' }} />
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>{transaction.dueType}</Typography>
        <Typography variant="caption" color="text.secondary">
          {new Date(transaction.paymentDateTime).toLocaleDateString()} • {transaction.paymentMode}
        </Typography>
      </Box>
    </Box>
    <Typography variant="body1" sx={{ 
      fontWeight: 'bold',
      color: '#00A86B'
    }}>
      ₹{transaction.paymentAmount.toLocaleString()}
    </Typography>
  </Box>
);

const TenantHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { dues, loading, complaints } = useSelector((state) => state.dueAndComplaint);
  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);

  const totalDueAmount = useMemo(() => {
    return dues
      .filter(due => due.status === 'Pending')
      .reduce((total, due) => total + due.dueAmount, 0);
  }, [dues]);

  const pendingComplaints = useMemo(() => 
    complaints.filter(complaint => complaint.status !== 'Resolved').length,
    [complaints]
  );

  const transactions = dues
    .filter((due) => due.status === 'Paid')
    .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime))
    .slice(0, 3);

  if (loading) return <LogoDispersionLoader />;
  if (!tenantDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>No tenant details available. Please try logging in again.</Typography>
      </Box>
    );
  }

  return (
    <Container sx={{ bgcolor: '#FCFCFC', minHeight: '100vh', py: 0, px: 0 }}>
      {/* Header Section */}
      <Box sx={{ 
        background: 'linear-gradient(-45deg, rgba(82,192,183,1) 0%, rgba(3,160,162,1) 50%, rgba(0,73,82,1) 100%)',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        pb: 6
      }}>
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,borderBottom:'1px solid rgba(255, 255, 255, 0.2)'}}>
          <Box sx={{ml:2}}> 
          <Typography variant="body1" sx={{ color: 'white', opacity: 0.8 }}>
            Welcome back, 
          </Typography>
          <Typography variant="h5" sx={{ 
            color: 'white', 
            fontWeight: '600', 
            textTransform: 'capitalize'
          }}>
            {tenantDetails?.tenant.name}
          </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Badge badgeContent={pendingComplaints} color="error">
              <IconButton sx={{ color: 'white' }}>
                <NotificationsIcon />
              </IconButton>
            </Badge>
            <Avatar
              src={tenantDetails.tenant.profilePicture ? `${BASE_URL}${tenantDetails.tenant.profilePicture}` : undefined}
              alt={tenantDetails.tenant.name}
              sx={{ 
                cursor: 'pointer',
                border: '2px solid white'
              }}
              onClick={() => navigate('/tapp-profile')}
            >
              {!tenantDetails.tenant.profilePicture && <PersonIcon />}
            </Avatar>
          </Box>
        </Box>

        <Box sx={{ px: 3, pt: 0 }}>
          
          
          <Card sx={{ 
            mt: 0, 
           // borderRadius: 3,
          // borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            bgcolor: 'transparent',
            boxShadow:0,
           // backdropFilter: 'blur(10px)',
           // border: '1px solid rgba(255, 255, 255, 0.2)'
          }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography sx={{ color: 'white', opacity: 0.8 }}>
                    Current Balance Due
                  </Typography>
                  <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mt: 1 }}>
                    ₹{totalDueAmount.toLocaleString()}
                  </Typography>
                </Box>
                <ButtonBase
                  onClick={() => navigate('/tapp-dues')}
                  sx={{
                    bgcolor: 'white',
                    color: 'black',
                    fontWeight: '500',
                    borderRadius: '10px',
                    px: 3,
                    py: 1
                  }}
                >
                  View Details
                </ButtonBase>
              </Box>
              
              <Box sx={{ 
                mt: 2, 
                display: 'flex', 
                gap: 2, 
                justifyContent: 'space-around',
                borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                pt: 2
              }}>
                <Box sx={{ textAlign: 'center', color: 'white' }}>
                  <HomeIcon sx={{ fontSize: 20, mb: 0.5 }} />
                  <Typography variant="caption" display="block">
                    Room {tenantDetails.room.name}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', color: 'white' }}>
                  <ReceiptIcon sx={{ fontSize: 20, mb: 0.5 }} />
                  <Typography variant="caption" display="block">
                    Rent ₹{tenantDetails.fixedRent}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', color: 'white' }}>
                  <DescriptionIcon sx={{ fontSize: 20, mb: 0.5 }} />
                  <Typography variant="caption" display="block">
                    {complaints.length} Issues
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
              
      {/* Quick Actions */}
      <Box sx={{ p: 2, mt: -6, bgcolor: '#FFF', borderRadius: '40px 40px 0 0' }}>
        <Card sx={{ borderRadius: 3, mb: 3 ,boxShadow:0}}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
              Quick Actions
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ButtonBase
                  onClick={() => navigate('/TenantPaymentRequest')}
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: '#E5F4F3'
                  }}
                >
                  <PaymentIcon sx={{ color: '#167D7F', fontSize: 28, mb: 1 }} />
                  <Typography variant="caption" sx={{ color: '#167D7F', fontWeight: 500 }}>
                    Pay Due
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item xs={4}>
                <ButtonBase
                  onClick={() => navigate('/tapp-addcomplaint')}
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: `${colors.peach}15`,
                  }}
                >
                  <AssignmentIcon sx={{ color: colors.peach, fontSize: 28, mb: 1 }} />
                  <Typography variant="caption" sx={{ color: colors.peach, fontWeight: 500 }}>
                    Report Issue
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item xs={4}>
                <ButtonBase
                  onClick={() => navigate('/tapp-transactions')}
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: '#E8F5E9'
                  }}
                >
                  <ReceiptIcon sx={{ color: '#4CAF50', fontSize: 28, mb: 1 }} />
                  <Typography variant="caption" sx={{ color: '#4CAF50', fontWeight: 500 }}>
                    Payments
                  </Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Recent Transactions */}
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Recent Transactions
        </Typography>
        <Card sx={{ borderRadius: 3, mb: 3 ,boxShadow:0}}>
          <CardContent sx={{ p: 0 }}>
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <React.Fragment key={transaction._id}>
                  <TransactionItem transaction={transaction} />
                  {index < transactions.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="text.secondary">No recent transactions</Typography>
              </Box>
            )}
            {transactions.length > 0 && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <ButtonBase 
                  onClick={() => navigate('/tapp-transactions')}
                  sx={{ color: '#167D7F' }}
                >
                  View All Transactions
                </ButtonBase>
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Other Actions */}
        <ActionCard
          icon={AssignmentIcon}
          title="Active Complaints"
          subtitle={`You have ${pendingComplaints} pending complaints`}
          onClick={() => navigate('/tapp-complaints')}
          color= {`${colors.peach}15`}
          textColor= {colors.peach}
        />

        <ActionCard
          icon={StarIcon}
          title="Give Feedback"
          subtitle="Help us improve Rizzy App"
          color="#E8F5E9"
          textColor="#4CAF50"
        />

        
      </Box>
    </Container>
  );
};

export default TenantHome;