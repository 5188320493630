// src/pages/LoginPage.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchDuesAndComplaints } from '../features/duesComplaintsSlice'; // Redux actions
import { login } from '../features/authSlice'; // Redux actions
import { BASE_URL } from '../utils/IP'; 




const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {

      console.log('Attempting to log in with cred:',credentials);
      const response = await axios.post(`${BASE_URL}/tapp-userauth/tenant-login`, credentials);
      const { token } = response.data;
      console.log('Login - Token received:', token);
       dispatch(login(token));
      console.log('Fetching data...');
        navigate('/tapp-home');
      } catch (error) {
        console.error('Login - Error logging in:', error);
        if (error.response && error.response.data) {
          setError(error.response.data.message || 'An error occurred during login.');
        } else {
          setError('Network error. Please ensure the backend server is running and accessible.');
        }
      }
    };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 10, p: 3, boxShadow: 1, borderRadius: 2 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Tenant Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Username or Mobile Number"
          name="username"
          value={credentials.username}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          name="password"
          value={credentials.password}
          onChange={handleChange}
          margin="normal"
          required
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Login
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;