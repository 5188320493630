import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  Chip,
  Divider,
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import { BASE_URL } from '../utils/IP';
import colors from '../styles/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Report,Pending,AccessTime,PriorityHigh,Close} from '@mui/icons-material';


const TappComplaintDetails = () => {
  const { complaintId } = useParams();
  const navigate = useNavigate();
  const { complaints, loading } = useSelector(state => state.dueAndComplaint);
  const [comment, setComment] = useState('');
  const [commentMedia, setCommentMedia] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const complaint = complaints.find(c => c._id === complaintId);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const handleBackClick = () => {
    navigate(-1, { state: { scrollToTop: true } })
  };


  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl);
    setOpenImagePreview(true);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('text', comment);
    formData.append('commenterType', 'tenant');
    if (commentMedia) {
      formData.append('media', commentMedia);
    }

    try {
      await axios.post(`${BASE_URL}/complaints/${complaintId}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setComment('');
      setCommentMedia(null);
      // The WebSocket middleware will handle updating the Redux store
    } catch (error) {
      console.error('Error submitting comment:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (loading || !complaint) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const renderComment = (comment, index) => {
    const isHomeowner = comment.commenterType === 'homeowner';
    return (
      <ListItem 
        key={index} 
        alignItems="flex-start"
        sx={{
          flexDirection: isHomeowner ? 'row' : 'row-reverse',
          justifyContent: isHomeowner ? 'flex-start' : 'flex-end',
        }}
      >
        <Avatar sx={{ bgcolor: isHomeowner ? 'primary.main' : 'secondary.main', mr: isHomeowner ? 2 : 0, ml: isHomeowner ? 0 : 2 }}>
          {isHomeowner ? 'H' : 'T'}
        </Avatar>
        <Box
          sx={{
            maxWidth: '70%',
            backgroundColor: isHomeowner ? 'primary.light' : 'secondary.light',
            borderRadius: 2,
            p: 2,
            mb: 2
          }}
        >
          <Typography variant="body1">{comment.text}</Typography>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
            {new Date(comment.date).toLocaleString()}
          </Typography>
          {comment.media && (
            <Box 
              component="img"
              src={`${BASE_URL}/${comment.media}`}
              alt={`Comment media ${index + 1}`}
              sx={{ 
                width: '100%',
                maxHeight: '200px',
                objectFit: 'cover',
                borderRadius: 1,
                mt: 1,
                cursor: 'pointer'
              }}
              onClick={() => handleImageClick(`${BASE_URL}/${comment.media}`)}
            />
          )}
        </Box>
      </ListItem>
    );
  };

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Complaint Details</Typography>
      </Box>

      {/* Complaint Details */}
      <Paper elevation={0} sx={{ m: 2, p: 1 }}>
        

        <Box sx={{ mb: 3 }}>
  {/* Category Tag and Status */}
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    mb: 2,
  }}>
    {/* Category with icon */}
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      bgcolor: `${colors.teal}10`,
      px: 1.5,
      py: 0.5,
      borderRadius: '16px',
    }}>
      <Report sx={{ 
        fontSize: 18, 
        color: colors.teal,
        mr: 0.5 
      }}/>
      <Typography 
        variant="subtitle2" 
        sx={{ 
          color: colors.teal,
          fontWeight: 600,
          fontSize: '0.9rem',
          textTransform: 'capitalize'
        }}
      >
        {complaint.category}
      </Typography>
    </Box>

    {/* Status Chip */}
    <Chip 
      icon={complaint.status === 'Resolved' ? 
        <CheckCircleOutlineIcon sx={{ fontSize: 16 }}/> : 
        <PriorityHigh sx={{ fontSize: 16 }}/>
      }
      label={complaint.status} 
      size="small"
      sx={{
        borderRadius: '12px',
        px: 1,
        '& .MuiChip-label': {
          px: 1,
          fontSize: '0.75rem',
          fontWeight: 600
        },
        bgcolor: complaint.status === 'Resolved' ? 
          `${colors.teal}15` : 
          '#FFB74D20',
        color: complaint.status === 'Resolved' ? 
          colors.teal : 
          '#F57C00',
        border: 'none'
      }}
    />
  </Box>

  {/* Complaint Info Card */}
  <Paper 
    elevation={0}
    sx={{ 
      p: 2,
      bgcolor: '#f8f9fa',
      borderRadius: 2,
      border: '1px solid #edf2f7'
    }}
  >
    {/* Date and Priority Row */}
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      mb: 1.5,
      gap: 2
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        color: 'text.secondary',
        fontSize: '0.75rem'
      }}>
        <AccessTime sx={{ fontSize: 16, mr: 0.5 }}/>
        <Typography variant="caption">
          {new Date(complaint.date).toLocaleDateString()}
        </Typography>
      </Box>
      
      
    </Box>

    {/* Description */}
    <Box sx={{ position: 'relative' }}>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'text.primary',
          lineHeight: 1.6,
          fontSize: '0.875rem',
          position: 'relative',
          pl: 3,
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '2px',
            bgcolor: colors.teal,
            borderRadius: '4px'
          }
        }}
      >
        {complaint.description}
      </Typography>
    </Box>
  </Paper>

  {/* Media Preview (if exists) */}
  {complaint.media && complaint.media.length > 0 && (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 1
      }}>
        <ImageIcon sx={{ 
          fontSize: 18,
          color: colors.teal
        }}/>
        <Typography 
          variant="caption" 
          sx={{ 
            color: colors.teal,
            fontWeight: 500
          }}
        >
          Attached Images ({complaint.media.length})
        </Typography>
      </Box>
      
      <Box sx={{ 
        display: 'flex',
        gap: 1,
        overflowX: 'auto',
        pb: 1,
        '&::-webkit-scrollbar': {
          height: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#E0E0E0',
          borderRadius: '4px',
        }
      }}>
        {complaint.media.map((mediaPath, index) => (
          <Box
            key={index}
            component="img"
            src={`${BASE_URL}/${mediaPath}`}
            alt={`Complaint media ${index + 1}`}
            onClick={() => handleImageClick(`${BASE_URL}/${mediaPath}`)}
            sx={{
              width: 80,
              height: 80,
              borderRadius: 2,
              objectFit: 'cover',
              cursor: 'pointer',
              flexShrink: 0,
              border: `1px solid ${colors.teal}20`,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)'
              }
            }}
          />
        ))}
      </Box>
    </Box>
  )}
  
  <Divider sx={{ 
    my: 3,
    borderStyle: 'solid',
    borderColor: `${colors.secondary}30`
  }}/>
</Box>

        

        {/* Comments Section */}
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Comments:
        </Typography>
        <List sx={{ width: '100%', p: 0 }}>
  {complaint.comments && complaint.comments.map((comment, index) => (
    <ListItem 
      key={index} 
      alignItems="flex-start"
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: comment.commenterType === 'tenant' ? 'flex-end' : 'flex-start',
        width: '100%',
        mb: 2,
        px: 2,
      }}
    >
      {comment.commenterType !== 'tenant' && (
        <ListItemAvatar sx={{ minWidth: 40 }}>
          <Avatar sx={{ 
            width: 32, 
            height: 32, 
            bgcolor: colors.primary 
          }}>
            <PersonIcon sx={{ color: colors.secondary, fontSize: 20 }} />
          </Avatar>
        </ListItemAvatar>
      )}
      
      <ListItemText
        sx={{
          margin: 0,
          maxWidth: '70%',
          '.MuiListItemText-primary': {
            backgroundColor: comment.commenterType === 'tenant' ? colors.teal : '#f5f5f5',
            color: comment.commenterType === 'tenant' ? colors.secondary : 'inherit',
            padding: '8px 12px',
            borderRadius: '12px',
            borderTopRightRadius: comment.commenterType === 'tenant' ? 0 : '12px',
            borderTopLeftRadius: comment.commenterType === 'tenant' ? '12px' : 0,
            wordBreak: 'break-word',
          },
          '.MuiListItemText-secondary': {
            textAlign: comment.commenterType === 'tenant' ? 'right' : 'left',
            px: 1,
          },
        }}
        primary={comment.text}
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
              sx={{ 
                display: 'block', 
                fontSize: '0.8rem', 
                mt: 0.5,
              }}
            >
              {new Date(comment.date).toLocaleString()}
            </Typography>
            {comment.media && (
              <Box 
                component="img"
                src={`${BASE_URL}/${comment.media}`}
                alt={`Comment media ${index + 1}`}
                sx={{ 
                  width: '100px', 
                  height: '100px', 
                  objectFit: 'cover', 
                  borderRadius: '8px',
                  mt: 1,
                  cursor: 'pointer',
                  display: 'block',
                  ml: comment.commenterType === 'tenant' ? 'auto' : 0,
                }}
                onClick={() => handleImageClick(`${BASE_URL}/${comment.media}`)}
              />
            )}
          </>
        }
      />

      {comment.commenterType === 'tenant' && (
        <ListItemAvatar sx={{ 
          minWidth: 40,
          ml: 1,
        }}>
          <Avatar sx={{ 
            width: 32, 
            height: 32, 
            bgcolor: colors.primary 
          }}>
            <PersonIcon sx={{ color: colors.secondary, fontSize: 20 }} />
          </Avatar>
        </ListItemAvatar>
      )}
    </ListItem>
  ))}
</List>

        {/* Add Comment Form */}
        <Box 
        component="form" 
        onSubmit={handleCommentSubmit}
        sx={{ 
          mt: 2,
          position: 'sticky',
          bottom: 16,
          bgcolor: '#fff',
          borderRadius: 2,
          //boxShadow: '0 -4px 20px rgba(0,0,0,0.05)',
          p: 2,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              backgroundColor: '#f8f9fa',
              '&.Mui-focused': {
                backgroundColor: '#fff',
                boxShadow: '0 0 0 2px rgba(26, 163, 147, 0.1)',
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="comment-media-file"
                  type="file"
                  onChange={(e) => setCommentMedia(e.target.files[0])}
                />
                <label htmlFor="comment-media-file">
                  <IconButton 
                    component="span"
                    sx={{ 
                      color: colors.teal,
                      '&:hover': { bgcolor: `${colors.teal}15` },
                    }}
                  >
                    <AttachFileIcon fontSize="small" />
                  </IconButton>
                </label>
                <IconButton 
                  type="submit"
                  sx={{ 
                    color: colors.teal,
                    '&:hover': { bgcolor: `${colors.teal}15` },
                  }}
                >
                  <SendIcon fontSize="small" />
                </IconButton>
              </Box>
            ),
          }}
        />
        
        {commentMedia && (
          <Paper
            elevation={0}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mt: 1,
              p: 1,
              bgcolor: `${colors.teal}08`,
              borderRadius: 2,
            }}
          >
            <ImageIcon sx={{ color: colors.teal, mr: 1, fontSize: 20 }} />
            <Typography 
              variant="caption" 
              sx={{ 
                color: colors.teal,
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {commentMedia.name}
            </Typography>
            <IconButton 
              size="small" 
              onClick={() => setCommentMedia(null)}
              sx={{ color: colors.teal }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Paper>
        )}
      </Box>

        {complaint.resolution && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              Resolution:
            </Typography>
            <Typography variant="body1" paragraph>
              {complaint.resolution.text}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Resolved on: {new Date(complaint.resolution.date).toLocaleString()}
            </Typography>
            {complaint.resolution.media && (
              <Box 
                component="img"
                src={`${BASE_URL}/${complaint.resolution.media}`}
                alt="Resolution media"
                sx={{ 
                  width: '100px', 
                  height: '100px', 
                  objectFit: 'cover', 
                  borderRadius: '8px',
                  mt: 1,
                  cursor: 'pointer'
                }}
                onClick={() => handleImageClick(`${BASE_URL}/${complaint.resolution.media}`)}
              />
            )}
          </>
        )}
      </Paper>

      <Dialog open={openImagePreview} onClose={() => setOpenImagePreview(false)} maxWidth="md">
        <img src={previewImage} alt="Full size preview" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </Box>
  );
};

export default TappComplaintDetails;