import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utils/IP';

const initialState = {
  dues: [],
  complaints: [],
  currentOccupancy: null,
  occupancyHistory: [],
  loading: false,
  error: null
};

export const fetchDuesAndComplaints = createAsyncThunk(
  'dueAndComplaint/fetchDuesAndComplaints',
  async (dataType = 'all', { getState }) => {
    const { auth } = getState();
    const { currentOccupancyId, tenantId } = auth.user;

    if (!currentOccupancyId) throw new Error('No current occupancy found');

    const token = localStorage.getItem('token');
    const config = { headers: { 'x-auth-token': token } };

    let responses = {};

    if (dataType === 'all' || dataType === 'dues') {
      responses.duesResponse = await axios.get(`${BASE_URL}/dues/tenant/${tenantId}`, config);
    }
    if (dataType === 'all' || dataType === 'complaints') {
      responses.complaintsResponse = await axios.get(`${BASE_URL}/complaints/tenant/${tenantId}`, config);
    }
    if (dataType === 'all' || dataType === 'occupancy') {
      responses.occupancyResponse = await axios.get(`${BASE_URL}/tenants/occupancy/${currentOccupancyId}`, config);
    }

    return {
      dataType,
      dues: responses.duesResponse?.data,
      complaints: responses.complaintsResponse?.data,
      currentOccupancy: responses.occupancyResponse?.data
    };
  }
);

export const fetchOccupancyHistory = createAsyncThunk(
  'dueAndComplaint/fetchOccupancyHistory',
  async (_, { getState }) => {
    const { auth } = getState();
    const { tenantId } = auth.user;

    const token = localStorage.getItem('token');
    const config = { headers: { 'x-auth-token': token } };

    const response = await axios.get(`${BASE_URL}/tenants/tenant/${tenantId}/history`, config);

    const occhist = response;
    console.log('occhist',occhist)
    return response.data;
  }
);

export const submitPaymentRequest = createAsyncThunk(
  'dueAndComplaint/submitPaymentRequest',
  async (paymentData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/dues/record-payment/tenant/${paymentData.tenantId}`, paymentData, {
        headers: { 
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dueAndComplaintSlice = createSlice({
  name: 'dueAndComplaint',
  initialState,
  reducers: {
    resetDueAndComplaint: () => initialState,
    updateTenantDocuments: (state, action) => {
      if (state.currentOccupancy && state.currentOccupancy.tenant) {
        state.currentOccupancy.tenant.documents = action.payload;
      }
    },
    updateTenantProfilePicture: (state, action) => {
      if (state.currentOccupancy?.tenant?._id === action.payload.tenantId) {
        state.currentOccupancy.tenant.profilePicture = action.payload.profilePicture;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDuesAndComplaints.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDuesAndComplaints.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.dataType === 'all' || action.payload.dataType === 'dues') {
          state.dues = action.payload.dues || state.dues;
        }
        if (action.payload.dataType === 'all' || action.payload.dataType === 'complaints') {
          state.complaints = action.payload.complaints || state.complaints;
        }
        if (action.payload.dataType === 'all' || action.payload.dataType === 'occupancy') {
          state.currentOccupancy = action.payload.currentOccupancy || state.currentOccupancy;
        }
      })
      .addCase(fetchDuesAndComplaints.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchOccupancyHistory.fulfilled, (state, action) => {
        console.log('fullfilling occ')
        state.occupancyHistory = action.payload;
      })
      .addCase(submitPaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitPaymentRequest.fulfilled, (state, action) => {
        state.loading = false;
        const updatedDueIndex = state.dues.findIndex(due => due._id === action.payload.due._id);
        if (updatedDueIndex !== -1) {
          state.dues[updatedDueIndex] = action.payload.due;
        }
      })
      .addCase(submitPaymentRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDueAndComplaint,updateTenantDocuments } = dueAndComplaintSlice.actions;

// Selectors
export const selectDues = (state) => state.dueAndComplaint.dues;
export const selectComplaints = (state) => state.dueAndComplaint.complaints;
export const selectCurrentOccupancy = (state) => state.dueAndComplaint.currentOccupancy;
export const selectOccupancyHistory = (state) => state.dueAndComplaint.occupancyHistory;

export const selectPaidDues = createSelector(
  [selectDues],
  (dues) => dues
    .filter((due) => due.status === 'Paid')
    .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime))
);

export default dueAndComplaintSlice.reducer;