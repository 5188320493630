import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOccupancyHistory, selectOccupancyHistory } from '../features/duesComplaintsSlice';
import { 
  Container, Box, Typography, Card, CardContent, IconButton, 
  Collapse, Grid, Divider, CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const formatDateRange = (startDate, endDate) => {
    const formatDate = (date) => {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'short' });
      const year = d.getFullYear().toString().substr(-2);
      return `${month} ${year}`;
    };
  
    const start = formatDate(startDate);
    const end = formatDate(endDate);
  
    return `${start} - ${end}`;
  };

const PastPropertiesComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const occupancyHistory = useSelector(selectOccupancyHistory);
  const [expandedId, setExpandedId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchOccupancyHistory());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleBackClick = () => {
    navigate('/tapp-profile');
  };

  if (loading) {
    return (
      <Box sx={{ color:'#000',display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', pt: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Past Properties</Typography>
      </Box>

      <Container sx={{ mt: 2 }}>
        {occupancyHistory.length === 0 ? (
          <Typography variant="body1" sx={{ textAlign: 'center', mt: 4 }}>
            You have no past properties to display.
          </Typography>
        ) : (
          occupancyHistory.map((occupancy) => (
            <Card key={occupancy._id} sx={{ mb: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="h6">{occupancy.property.name}</Typography>
                    
                    <Typography variant="body2" color="text.secondary">
                      {occupancy.property.city}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatDateRange(occupancy.moveIn, occupancy.moveOut)}
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleExpandClick(occupancy._id)}
                      aria-expanded={expandedId === occupancy._id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    
                  </Grid>
                </Grid>
                <Collapse in={expandedId === occupancy._id} timeout="auto" unmountOnExit>
                  <Box sx={{ mt: 2 }}>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body2" sx={{textTransform:'capitalize'}}>
                      <strong>Room:</strong> {occupancy.room.name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Floor:</strong> {occupancy.room.floor}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Move-in Date:</strong> {new Date(occupancy.moveIn).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Move-out Date:</strong> {new Date(occupancy.moveOut).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Rent:</strong> ₹{occupancy.fixedRent}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Address:</strong> {occupancy.property.address}, {occupancy.property.city}, {occupancy.property.pincode}
                    </Typography>
                  </Box>
                </Collapse>
              </CardContent>
            </Card>
          ))
        )}
      </Container>
    </Box>
  );
};

export default PastPropertiesComponent;