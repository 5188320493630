import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Avatar, 
  IconButton, 
  Card, 
  CardContent, 
  Button, 
  Divider, 
  Grid, 
  Link, 
  Drawer, 
  CircularProgress, 
  Input, 
  Modal, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  ListItemAvatar,
  Snackbar,
  Alert,Chip,Tooltip,Fade,ImageList,ImageListItem
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../features/authSlice';
import axios from 'axios';
import { BASE_URL } from '../utils/IP';
import CentralDialog from '../utils/CentralDialog';
import { updateTenantDocuments } from '../features/duesComplaintsSlice';
import { useNavigate } from 'react-router-dom';
import {
  AttachFile as AttachFileIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Upload as UploadIcon,
  PictureAsPdf as PdfIcon,
  InsertDriveFile as FileIcon,

} from '@mui/icons-material';

// Styled components
const BottomCustomDrawer = styled(Drawer)({
  '& .MuiPaper-root': {
    backgroundColor: '#FFF',
    borderRadius: '20px 20px 0 0',
  },
});

const FullSizeImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  maxHeight: 'calc(100vh - 100px)',
});

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  marginBottom: theme.spacing(1),
  border: '2px solid white',
  boxShadow: '0px 0px 8px rgba(0,0,0,0.2)',
  '& .MuiAvatar-img': {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
}));

const DocumentSection = ({
  tenantDetails,
  handleDocumentUpload,
  handleDeleteDocument,
  uploadingDocument,
  BASE_URL
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const handlePreview = (doc) => {
    const extension = doc.name.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);

    if (isImage) {
      setPreviewContent(doc);
      setPreviewOpen(true);
    } else {
      // For PDFs and other files, just open in new tab
      window.open(`${BASE_URL}${doc.path}`, '_blank');
    }
  };

  const getDocumentThumbnail = (doc) => {
    const extension = doc.name.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);

    if (isImage) {
      return (
        <img
          src={`${BASE_URL}${doc.path}`}
          alt={doc.name}
          style={{ 
            width: '100%', 
            height: '100%', 
            objectFit: 'cover'
          }}
          loading="lazy"
        />
      );
    } else if (extension === 'pdf') {
      return (
        <Box sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(244, 67, 54, 0.1)',
        }}>
          <PdfIcon sx={{ fontSize: 40, color: '#F44336' }} />
        </Box>
      );
    } else {
      return (
        <Box sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(33, 150, 243, 0.1)',
        }}>
          <FileIcon sx={{ fontSize: 40, color: '#2196F3' }} />
        </Box>
      );
    }
  };

  const renderPreviewContent = (doc) => {
    if (!doc) return null;
    
    return (
      <img
        src={`${BASE_URL}${doc.path}`}
        alt={doc.name}
        style={{
          maxWidth: '100%',
          maxHeight: '80vh',
          objectFit: 'contain'
        }}
      />
    );
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
        px: 2
      }}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          Documents & Files
        </Typography>
        <Input
          type="file"
          onChange={handleDocumentUpload}
          style={{ display: 'none' }}
          id="document-upload"
          inputProps={{ accept: '.jpg,.jpeg,.png,.pdf,.doc,.docx', multiple: true }}
        />
        <label htmlFor="document-upload">
          <Button
            component="span"
            variant="contained"
            startIcon={<UploadIcon />}
            disabled={uploadingDocument}
            sx={{
              bgcolor: '#1AA393',
              '&:hover': { bgcolor: '#158677' },
              textTransform: 'none',
              borderRadius: '12px',
              boxShadow: 'none'
            }}
          >
            {uploadingDocument ? 'Uploading...' : 'Upload'}
          </Button>
        </label>
      </Box>

      <Box sx={{ px: 2 }}>
        <ImageList cols={3} gap={16}>
          {tenantDetails.tenant.documents && tenantDetails.tenant.documents.map((doc, index) => (
            <ImageListItem 
              key={index}
              sx={{ 
                cursor: 'pointer',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                },
                transition: 'all 0.3s ease',
                position: 'relative',
                height: '120px !important',
              }}
              onClick={() => handlePreview(doc)}
            >
              {getDocumentThumbnail(doc)}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteDocument(doc.path);
                }}
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  padding: '4px',
                }}
              >
                <DeleteIcon sx={{ fontSize: 18, color: 'white' }} />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>

        {(!tenantDetails.tenant.documents || tenantDetails.tenant.documents.length === 0) && (
          <Box sx={{ 
            textAlign: 'center', 
            py: 8,
            bgcolor: 'rgba(0,0,0,0.02)',
            borderRadius: '16px',
            border: '2px dashed rgba(0,0,0,0.1)'
          }}>
            <UploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Upload documents, images or files
            </Typography>
          </Box>
        )}
      </Box>

      {/* Preview Modal - Only for Images */}
      <Modal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        closeAfterTransition
      >
        <Fade in={previewOpen}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '800px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: '16px',
            outline: 'none',
          }}>
            <IconButton
              onClick={() => setPreviewOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ mt: 2 }}>
              {previewContent && renderPreviewContent(previewContent)}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};


const YourAccount = () => {
  // State management
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fullViewOpen, setFullViewOpen] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    title: '',
    content: '',
    type: '',
    onConfirm: null
  });
// Dialog closing handler
  const handleDialogClose = () => {
    setDialogConfig(prev => ({ ...prev, open: false }));
  };

  // Redux hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);

  // Cleanup effect
  useEffect(() => {
    return () => {
      setUploading(false);
      setUploadingDocument(false);
    };
  }, []);
  

  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    // Validate file size
    if (file.size > 5 * 1024 * 1024) {
      setDialogConfig({
        open: true,
        title: 'Error',
        content: 'File size should be less than 5MB',
        type: 'error'
      });
      return;
    }
  
    // Validate file type
    if (!file.type.match(/image\/(jpg|jpeg|png|gif)/)) {
      setDialogConfig({
        open: true,
        title: 'Error',
        content: 'Only image files (jpg, jpeg, png, gif) are allowed',
        type: 'error'
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('profilePicture', file);
  
    setUploading(true);
  
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${BASE_URL}/tenants/${tenantDetails.tenant._id}/profile-picture`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          }
        }
      );

      console.log('success dialog before',dialogConfig)

       // Show success dialog and keep it open
       setDialogConfig({
        open: true,
        title: 'Success',
        content: 'Profile picture updated successfully!',
        type: 'success',
        autoClose: false,
        onConfirm: null
      });
      console.log('success dialog',dialogConfig)
      // Note: No need to reload or manually update state
      // The WebSocket event will trigger the state update through the occupancy data
  
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      setDialogConfig({
        open: true,
        title: 'Error',
        content: error.response?.data?.message || 'Failed to upload profile picture. Please try again.',
        type: 'error',
        autoClose: false
      });
    } finally {
      setUploading(false);
    }
  };

  // Document handlers
  const handleDocumentUpload = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setUploadingDocument(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('documents', files[i]);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${BASE_URL}/tenants/${tenantDetails.tenant._id}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
          }
        }
      );

      dispatch(updateTenantDocuments(response.data.tenant.documents));
      setSnackbarState({
        open: true,
        message: 'Documents uploaded successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error uploading documents:', error);
      setSnackbarState({
        open: true,
        message: 'Failed to upload documents',
        severity: 'error'
      });
    } finally {
      setUploadingDocument(false);
    }
  };

  const handleDeleteDocument = (documentPath) => {
    setDialogConfig({
      open: true,
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete this document?',
      type: 'confirm',
      onConfirm: () => confirmDeleteDocument(documentPath)
    });
  };

  const confirmDeleteDocument = async (documentPath) => {
    try {
      const token = localStorage.getItem('token');
      const keepDocuments = tenantDetails.tenant.documents
        .filter(doc => doc.path !== documentPath)
        .map(doc => doc.path);

      const response = await axios.post(
        `${BASE_URL}/tenants/${tenantDetails.tenant._id}/documents`,
        { keepDocuments },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        }
      );

      dispatch(updateTenantDocuments(response.data.tenant.documents));
      setSnackbarState({
        open: true,
        message: 'Document deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting document:', error);
      setSnackbarState({
        open: true,
        message: 'Failed to delete document',
        severity: 'error'
      });
    }
  };

  

  // Navigation handlers
  const handleBackClick = () => {
    navigate('/tapp-profile');
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/tapp-login');
  };

  // Utility functions
  const getDocumentIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf': return <PictureAsPdfIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif': return <ImageIcon />;
      default: return <InsertDriveFileIcon />;
    }
  };

  const handleViewDocument = (documentPath) => {
    window.open(`${BASE_URL}${documentPath}`, '_blank');
  };

  // Loading state
  if (loading || !user || !tenantDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', pt: 0 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Your Account</Typography>
      </Box>

      {/* Profile Section */}
      <Card sx={{ m: 2, bgcolor: '#FFF', borderRadius: 2, boxShadow: 0 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => setFullViewOpen(true)}>
            <ProfileAvatar
              src={tenantDetails.tenant.profilePicture ? `${BASE_URL}${tenantDetails.tenant.profilePicture}` : undefined}
              alt={tenantDetails.tenant.name}
            >
              {!tenantDetails.tenant.profilePicture && <PersonIcon sx={{ fontSize: 60 }} />}
            </ProfileAvatar>
            <Input
              type="file"
              onChange={handleProfilePictureUpload}
              style={{ display: 'none' }}
              id="profile-picture-upload"
              inputProps={{ accept: 'image/*' }}
            />
            <label htmlFor="profile-picture-upload">
              <IconButton 
                component="span" 
                sx={{ 
                  position: 'absolute', 
                  bottom: 0, 
                  right: 0, 
                  backgroundColor: '#03A0A2',
                  '&:hover': { backgroundColor: '#038789' },
                }}
                disabled={uploading}
                onClick={(e) => e.stopPropagation()}
              >
                {uploading ? (
                  <CircularProgress size={24} sx={{ color: 'white' }} />
                ) : (
                  <CameraAltIcon sx={{ color: 'white', fontSize: 18 }} />
                )}
              </IconButton>
            </label>
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textTransform: 'capitalize', mt: 1 }}>
            {tenantDetails.tenant.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {tenantDetails.room.name}, {tenantDetails.room.floor}, {tenantDetails.property.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {tenantDetails.property.address}, {tenantDetails.property.city} - {tenantDetails.property.pincode}
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2, ml: 2, mr: 2 }} />

      {/* Profile Information */}
      <Box sx={{ p: 2, ml: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Profile Information</Typography>
        <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Primary number</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.tenant.phone}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 2, ml: 2, mr: 2 }} />

      {/* Room Details */}
      <Box sx={{ p: 2, ml: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Room Details</Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Room number</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.room?.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Floor</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.room?.floor}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Monthly rent</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">₹{tenantDetails?.fixedRent}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Security deposit</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">₹{tenantDetails?.securityDeposit}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Joined on</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{new Date(tenantDetails?.moveIn).toLocaleDateString()}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 2, ml: 2, mr: 2 }} />

      <DocumentSection 
  tenantDetails={tenantDetails}
  handleDocumentUpload={handleDocumentUpload}
  handleDeleteDocument={handleDeleteDocument}
  uploadingDocument={uploadingDocument}
  BASE_URL={BASE_URL}
/>

      {/* Logout Button */}
      <Box sx={{ p: 2, mt: 2 }}>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          sx={{ borderColor: '#f44336', color: '#f44336' }}
          onClick={() => setDrawerOpen(true)}
        >
          Logout
        </Button>
      </Box>

      {/* Logout Confirmation Drawer */}
      <BottomCustomDrawer anchor="bottom" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ p: 2, width: 'auto' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Logout?</Typography>
          <Typography>Are you sure you want to log out? You can log back in anytime you want.</Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ 
              bgcolor: '#03A0A2', 
              color: '#fff', 
              mt: 2, 
              mb: 2, 
              p: 1.5, 
              fontWeight: '600', 
              borderRadius: '10px',
              '&:hover': {
                bgcolor: '#038789'
              }
            }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </BottomCustomDrawer>

      {/* Profile Picture Preview Modal */}
      <Modal
        open={fullViewOpen}
        onClose={() => setFullViewOpen(false)}
        aria-labelledby="full-view-profile-picture"
        aria-describedby="full-size-profile-picture"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          bgcolor: 'background.paper', 
          boxShadow: 24, 
          p: 2,
          borderRadius: 2,
          width: '90%',
          maxWidth: '600px',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden'
        }}>
          <IconButton
            onClick={() => setFullViewOpen(false)}
            sx={{ alignSelf: 'flex-end', mb: 1 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%', overflow: 'auto' }}>
            <FullSizeImage 
              src={`${BASE_URL}${tenantDetails.tenant.profilePicture}`} 
              alt={tenantDetails.tenant.name} 
            />
          </Box>
        </Box>
      </Modal>

      {/* Central Dialog for confirmations */}
      <CentralDialog
        open={dialogConfig.open}
        onClose={handleDialogClose}
        title={dialogConfig.title}
        content={dialogConfig.content}
        type={dialogConfig.type}
        onConfirm={dialogConfig.onConfirm}
        autoClose={dialogConfig.autoClose}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))} 
          severity={snackbarState.severity}
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default YourAccount;