import React from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, ButtonBase, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../utils/IP';



const ProfileScreen = () => {

const navigate = useNavigate();

const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);
const user = useSelector((state) => state.auth.user);
const loading = useSelector((state) => state.dueAndComplaint.loading);


if (loading || !user || !tenantDetails) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
}

//const tenantName=tenantdetails.name;

const handleBackClick = () => {
    navigate('/tapp-home'); // Go back to the previous screen
  };

const handleaccountClick = () => {
    navigate('/tapp-account'); // Go back to the previous screen
    };

    const handleComplaintlistClick = () => {
      navigate('/tapp-complaints'); // Navigate to the report complaint page
    };

    const handleDuelistClick = () => {
      navigate('/tapp-dues'); // Navigate to the report complaint page
    };

    
    const handleAllTransactionsClick = () => {
      navigate('/tapp-transactions'); // Navigate to the report complaint page
    };

    const handlePastPropertiesClick = () => {
      navigate('/tapp-pastproperties'); // Navigate to the report complaint page
    };

    const handleRoomProfileClick = () => {
      navigate('/RoomProfile'); // Navigate to the report complaint page
    };

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', pt: 0 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', p:2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)', mb:4}}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Profile</Typography>
      </Box>

      {/* Profile Card */}
      <Card onClick={handleaccountClick} sx={{ paddingTop:1, m: 2, bgcolor: '#E5F4F3', borderRadius: 2 ,boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)'}}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' , position: 'relative'}}>
        <Avatar
            src={tenantDetails.tenant.profilePicture ? `${BASE_URL}${tenantDetails.tenant.profilePicture}` : undefined}
            alt={tenantDetails.tenant.name}
            sx={{ width: 56, height: 56, mr: 2 }}
          >
            {!tenantDetails.tenant.profilePicture && <PersonIcon />}
          </Avatar>
          <Box sx={{ flexGrow: 1, pr: 4 ,overflow: 'hidden'  }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{tenantDetails?.tenant.name}</Typography>
            <Typography variant="body2" color="textSecondary" 
            sx={{
              wordBreak: 'inherit',
              overflowWrap: 'inherit',
              whiteSpace: 'normal',
            }}>
          {tenantDetails?.property?.name}, {tenantDetails?.property?.address}, {tenantDetails?.property?.city}, {tenantDetails?.property?.pincode}
  </Typography>
          </Box>
          <ChevronRightIcon 
          sx={{
            color: '#03A0A2',
            position: 'absolute',
            right: 16,
            top: '50%',
            transform: 'translateY(-50%)',
          }}/>
        </CardContent>
      </Card>

      {/* Options */}
      <Box sx={{ p: 2 }}>
        
          <ButtonBase
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height:'55px',
            border: '1px solid #03A0A2',
            borderRadius: 2,
            p: 1.5,
            mb: 2,
            bgcolor: 'white',
          }}
          onClick={handleRoomProfileClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ color:'#03A0A2', bgcolor: '#fff', mr: 2 }}><HomeIcon/></Avatar>
            <Typography variant="body1">Room Profile</Typography>
          </Box>
          <ChevronRightIcon sx={{color:'#03A0A2'}} />
        </ButtonBase>

        <ButtonBase
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height:'55px',
            border: '1px solid #03A0A2',
            borderRadius: 2,
            p: 1.5,
            mb: 2,
            bgcolor: 'white',
          }}
          onClick={handleAllTransactionsClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ color:'#03A0A2', bgcolor: '#fff', mr: 2 }}><ReceiptIcon/></Avatar>
            <Typography variant="body1">Transactions</Typography>
          </Box>
          <ChevronRightIcon sx={{color:'#03A0A2'}} />
        </ButtonBase>

        <ButtonBase
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height:'55px',
            border: '1px solid #03A0A2',
            borderRadius: 2,
            p: 1.5,
            mb: 2,
            bgcolor: 'white',
          }}
          onClick={handleComplaintlistClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ color:'#03A0A2', bgcolor: '#fff', mr: 2 }}><FeedbackIcon/></Avatar>
            <Typography variant="body1">Complaints</Typography>
          </Box>
          <ChevronRightIcon sx={{color:'#03A0A2'}} />
        </ButtonBase>

        <ButtonBase
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height:'55px',
            border: '1px solid #03A0A2',
            borderRadius: 2,
            p: 1.5,
            mb: 2,
            bgcolor: 'white',
          }}
          onClick={handleDuelistClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ color:'#03A0A2', bgcolor: '#fff', mr: 2 }}><HomeIcon/></Avatar>
            <Typography variant="body1">All Dues</Typography>
          </Box>
          <ChevronRightIcon sx={{color:'#03A0A2'}} />
        </ButtonBase>

        <ButtonBase
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height:'55px',
            border: '1px solid #03A0A2',
            borderRadius: 2,
            p: 1.5,
            mb: 2,
            bgcolor: 'white',
          }}
          onClick={handlePastPropertiesClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ color:'#03A0A2', bgcolor: '#fff', mr: 2 }}><FeedbackIcon/></Avatar>
            <Typography variant="body1">Past Properties</Typography>
          </Box>
          <ChevronRightIcon sx={{color:'#03A0A2'}} />
        </ButtonBase>

      </Box>
    </Box>
  );
};

export default ProfileScreen;